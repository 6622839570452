var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.res ? _c('div', [_vm._v(" Created : " + _vm._s(_vm.res.created_at ? _vm.humanDateTime(_vm.res.created_at) : ' - ') + " "), _c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": _vm.res.status == 'in_progress' ? 'warning' : ' - '
    }
  }, [_vm._v(_vm._s(_vm.res.status == 'in_progress' ? 'In Progress' : ' - '))]), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.getData();
      }
    }
  }, [_vm._v("Refresh")])], 1) : _c('div', [_vm._v(" Loading... ")])]), _c('b-col', {
    staticClass: "mt-1"
  }, [_c('b-progress', {
    staticClass: "mb-3",
    attrs: {
      "value": _vm.res.progress,
      "max": "100",
      "show-progress": ""
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }