<template>
  <main>
    <b-overlay :show="loading">
      <b-card>
        <b-row>
          <b-col cols="12">
            <div v-if="res">
              <!-- {{ res.created_at }} -->
              Created : {{ res.created_at ? humanDateTime(res.created_at) : ' - ' }} <b-badge class="ml-1"
                :variant="res.status == 'in_progress' ? 'warning' : ' - '">{{ res.status == 'in_progress' ?
                  'In Progress' : ' - ' }}</b-badge>
              <b-button size="sm" class="ml-1" variant="primary" @click="getData()">Refresh</b-button>
            </div>
            <div v-else>
              Loading...
            </div>
          </b-col>
          <b-col class="mt-1">
            <b-progress :value="res.progress" max="100" show-progress class="mb-3"></b-progress>
            <!-- <b-progress :value="res.progress" max="100" show-progress class="mb-3"></b-progress> -->
            <!-- <b-progress :value="res.progress" variant="warning" striped :animated="animate" class="mt-2"></b-progress> -->
            <!-- {{ humanDateTime(res.created_at) }} -->
            <!-- {{ res }} -->
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </main>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import {
  BAlert, BPopover, VBPopover, BFormRadio, BFormRadioGroup, BFormCheckboxGroup, BLink,
  BTable,
  BCard, BCardBody, BCardImg, BCardText, BCardTitle,
  VBTooltip, BTabs, BTab,
  BImg,
  BForm,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormDatepicker, BFormSpinbutton, BProgress, BProgressBar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import mixin from "./mixin";

import jsPDF from 'jspdf';
import 'jspdf-autotable';
// const maxLength = 20;
// const truncatedString = truncateString(originalString, maxLength);

export default {
  mixins: [mixin],
  components: {
    flatPickr, BProgress, BProgressBar,
    jsPDF,
    BFormDatepicker, BPopover, BTabs, BTab, BFormSpinbutton,
    BAlert, BFormRadio, BFormRadioGroup, BFormCheckboxGroup, BLink,
    ToastificationContent,
    FormFilterExport,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BCard, BCardBody, BCardImg, BCardText, BCardTitle,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BImg,
  },
  directives: {
    'b-popover': VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      res: null,
      itemData: null,
      loading: false,
      animate: true,

    };
  },
  computed: {

  },
  watch: {
    perPage(value) {
      if (value) {
        this.getGinee();
      }
    },
    // filter(keyword) {
    //  if (keyword.length > 2 || keyword.length == 0) {
    //    this.getTransaksi();
    // }
    //},
    currentPage(val) {
      if (val) {
        this.getGinee();
        // this.items
      }
    },
    // storemodel(newValue) {
    //   if (newValue == null) {
    //     this.storemodel == []
    //   }
    // },
    selectAll(newVal) {
      this.itemsacc.forEach(item => {
        item.selected = newVal;
      });
    }
  },
  methods: {
    getData() {
      const payload = {
        token: this.itemData.token,
      };
      // payload.channel = [this.channel]
      this.loading = true
      this.$store
        .dispatch("ginee/ceksync", payload)
        .then((res) => {
          this.res = res
          this.loading = false
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.loading = false
        });
    },
  },
  async mounted() {
    if (JSON.parse(sessionStorage.getItem('tokenSync'))) {
      this.itemData = JSON.parse(sessionStorage.getItem('tokenSync'))
      this.getData()
      // sessionStorage.removeItem('tokenSync');
    } else if (this.itemData) {
      this.getData()
      return
    }
  },
  async created() {
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>